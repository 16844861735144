import React, { Component } from 'react';
//import items from './data';
import Client from './contentful';
Client.getEntries({
    content_type:'beachResortRoomExample'
})
.then((response) => console.log(response.items));

const RoomContext=React.createContext();

// <RoomContext.Provided value={}

class RoomProvider extends Component {
    state={
        rooms:[],
        SortedRooms:[],
        FeaturedRooms:[],
        Loading:false,
        type:'all',
        capacity:1,
        price:0,
        minPrice:0,
        maxPrice:0,
        minSize:0,
        maxSize:0,
        breakfast:false,
        pets:false

    };
    //getData

    getData = async () => {
        try{
            let response = await Client.getEntries({
                content_type: "beachResortRoomExample"
            })
            let rooms=this.formatData(response.items);
            let FeaturedRooms=rooms.filter(room=>room.featured===true);
            let maxPrice = Math.max(...rooms.map(item=>item.price));
            let maxSize = Math.max(...rooms.map(item=>item.size));
        
            this.setState({
                rooms,FeaturedRooms,SortedRooms:rooms,loading:false,
                price:maxPrice,
                maxPrice,
                maxSize
            })
        }
        catch(error){
            console.log(error);
        }
    }

componentDidMount(){
 this.getData()
}        

formatData(items){
    let tempItems=items.map(item => {
    let id=item.sys.id;
    let images=item.fields.images.map(image=>image.fields.file.url);
    let room={...item.fields,images,id}
    

    return room;
    })
    return tempItems;
}
getRoom = (slug) =>{
    let TempRooms= [...this.state.rooms];
    const room= TempRooms.find(room=>room.slug === slug);
    return room;
}
handleChange = event =>{
    const target=event.target;
    const name = target.name;
    const value = target.type==='checkbox' ? target.checked : event.target.value;
    this.setState(
        {
        [name] : value
        },
        this.fiterRooms
    )
}
fiterRooms = () =>{
    let {rooms, type, capacity, price, minSize, maxSize, breakfast,pets} = this.state;
    let TempRooms=[...rooms];
    capacity = parseInt(capacity);
    price= parseInt(price);
    //filter by type
    if(type !== 'all'){
        TempRooms= TempRooms.filter(room=>room.type === type)
    }
    //filter by capacity
    if(capacity !== 1){
        TempRooms = TempRooms.filter(room=>room.capacity>=capacity)
    }
    //filter by price range
        TempRooms = TempRooms.filter(room=>room.price<=price)
    
        TempRooms = TempRooms.filter(room=> room.size>minSize && room.size<maxSize)
   
        if(breakfast){
        TempRooms = TempRooms.filter(room=>room.breakfast===true)
        }
        if(pets){
            TempRooms = TempRooms.filter(room=>room.pets===true)
            }

    this.setState({
        SortedRooms : TempRooms
    })
}


    render() {

        return (
            <RoomContext.Provider value={{...this.state, getRoom:this.getRoom, handleChange:this.handleChange}}>
                {this.props.children}
            </RoomContext.Provider>
        )
    }
}


const RoomConsumer= RoomContext.Consumer;

export function withRoomConsumer(Component) {
    return function ConsumerWrapper(props){
        return <RoomConsumer>
            {value => <Component {...props} context={value}></Component>}
        </RoomConsumer>
    }
    
}


export {RoomProvider,RoomConsumer,RoomContext};